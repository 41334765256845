/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import React from 'react'
import CustomLink from 'components/CustomLink'
import styled from 'styled-components'

const StyledButton = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: $font-size-m;
  font-weight: normal;
  line-height: 26px;
  padding: 8px 24px;
  border-radius: 59px;
  vertical-align: top;
  color: ${(props) => props.theme.color.text.light};
  background-color: ${(props) => props.theme.color.main};
  border: 2px solid ${(props) => props.theme.color.main};

  &:hover {
    color: ${(props) => props.theme.color.text.light};
    background-color: ${(props) => props.theme.color.contrast};
  }

  transition: all 0.3s;

&:focus {
  outline: none;
}
`
const ButtonDefault = ({ isAnchor, to, children, className }) => {
  return (
    <StyledButton className={className}>
      {!isAnchor ? (
        <CustomLink to={to} className={className}>{children}</CustomLink>
      ) : (
        <a href={to} className={className}>{children}</a>
      )}
    </StyledButton>
  )
}

export default ButtonDefault