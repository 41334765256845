/* eslint-disable react/no-array-index-key */
import React from 'react'
import { graphql } from 'gatsby'
import Plaatjie from '@ubo/plaatjie'

// Images
import checkBlue from 'img/check-blue.svg'

// Components
import SEO from 'components/SEO'
import Layout from 'components/Layout'
import CustomBreadCrumb from 'components/Common/Breadcrumb'
import styled from 'styled-components'
import Content from 'components/Content'
import ButtonDefault from 'components/Elements/ButtonDefault'
import GravityForm from 'components/GravityForm/GravityForm'

// Third Party

const Dienst = styled.div``

const Header = styled.section`
  color: #ffffff;
  height: 564px;
  position: relative;

  @media screen and (max-width: 1199px) {
    max-height: unset;
  }
  & h2 {
    font-size: 30px;
    margin-bottom: 0;
  }

  & p {
    font-size: 18px;
    margin: 2rem 0;
  }
`

const BGImage = styled(Plaatjie)`
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  height: 100%;
`

const StyledButton = styled(ButtonDefault)`
  background-color: #23cf53 !important;
  font-size: 18px;
`

const MainContent = styled(Content)`
  & h1 {
    text-transform: uppercase;
    font-size: 48px;
    margin-bottom: 1rem;

    @media (max-width: 991px) {
      font-size: 30px;
    }
  }

  & h2 {
    @media (max-width: 991px) {
      font-size: 20px;
    }
  }

  & p {
    @media (max-width: 991px) {
      font-size: 16px;
    }
  }

  & h3 {
    @media (max-width: 991px) {
      font-size: 22px;
    }
  }
`
const UPSColumnRight = styled.div`
  background: #d1d5e5;

  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;

  @media (max-width: 991px) {
    border-radius: 20px !important;
  }

  ul {
    list-style: none;
    margin-bottom: 0;

    li {
      font-size: 22px;
      margin-bottom: 2rem;

      @media (max-width: 991px) {
        font-size: 18px;

        & img {
          width: 25px;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 1100px;
    height: 100%;
    right: -1100px;
    z-index: 1;
    background-color: #d1d5e5;

    @media (max-width: 991px) {
      display: none;
    }
  }
`

const UPSColumnLeft = styled.div`
  background: #d1d5e5;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;

  @media (max-width: 991px) {
    border-radius: 20px !important;
  }

  ul {
    list-style: none;
    margin-bottom: 0;

    li {
      font-size: 22px;
      margin-bottom: 2rem;

      @media (max-width: 991px) {
        font-size: 18px;

        & img {
          width: 25px;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 1100px;
    height: 100%;
    left: -1100px;
    z-index: 1;
    background-color: #d1d5e5;

    @media (max-width: 991px) {
      display: none;
    }
  }
`

const StyledUl = styled.ul`
  @media (max-width: 991px) {
    margin-left: -2.1rem;
  }
`

const Box = styled.div`
  background-color: #272d46;
  border-radius: 20px;

  & h3,
  p {
    color: #fff;
  }
  & h3 {
    font-size: 26px;
    margin-bottom: 2rem;
  }
  & p {
    margin-bottom: 2rem;
  }
`

const AboutBG = styled.section`
  & .gatsby-image-wrapper {
    max-height: 602px;
    height: 100%;
    object-fit: cover;
    object-position: 0% 0%;

    @media (max-width: 991px) {
      height: 300px;
    }
  }
`

const AboutUs = styled.section`
  @media (min-width: 992px) {
    margin-top: -7rem;
  }
`

const ReviewTitle = styled(Content)`
  margin-bottom: -2rem;

  @media (max-width: 991px) {
    margin-bottom: -1rem;
  }

  & h2 {
    font-size: 85px;
    opacity: 0.1;

    @media (max-width: 991px) {
      font-size: 40px;
    }
  }
`

const ReviewBox = styled.div`
  background-color: #d1d5e5;
  height: 432px;

  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;

  @media (max-width: 991px) {
    border-radius: 20px !important;
  }

  &::before {
    content: '';
    position: absolute;
    background-color: #d1d5e5;
    width: 2000px;
    height: 100%;
    right: 100%;
    top: 0;
    bottom: 0;
  }

  & p {
    font-size: 27px;
    line-height: 43px;
    font-style: italic;

    @media (max-width: 991px) {
      font-size: 20px;
      line-height: 30px;
    }

    span {
      font-size: 18px;
      font-style: initial;
    }
  }
`

const ReviewImage = styled(Plaatjie)`
  right: 0;
  top: -160px;
  left: -20px;
  width: 296px;

  @media (max-width: 991px) {
    right: 20px;
    top: -153px;
    left: auto;
    width: 160px;
  }
`

const Appointment = styled.section`
  background-color: #272d46;

  & h2,
  p {
    color: #ffffff;
  }
`

const ColumnContent = styled.div`
  @media (max-width: 991px) {
    order: 2;
  }
`

const Video = styled.video`
  width: 540px;
  max-width: 100%;

  @media (min-width: 991px) {
    padding: 10px;
  }
`

const HuisVerkopenTemplate = ({ data: { page }, pageContext }) => (
  <Layout>
    {/* {() => ( */}
      <>
        <SEO seo={page.seo} />
        <Dienst>
          <CustomBreadCrumb
            data={page}
            className="py-2"
            pageContext={pageContext}
          />
          <Header>
            <div className="container h-100">
              <div className="row h-100 justify-content-left align-items-center">
                <div className="col-lg-6">
                  <MainContent
                    content={page.paginaVerkoopplannen.bannerNew.description}
                  />
                  <StyledButton
                    to={page.paginaVerkoopplannen.bannerNew.button.url}
                  >
                    {page.paginaVerkoopplannen.bannerNew.button.title}
                  </StyledButton>
                </div>
              </div>
            </div>
            <BGImage image={page.paginaVerkoopplannen.bannerNew.background} />
          </Header>

          {page.paginaVerkoopplannen.service.map((item, index) => (
            <>
              <section key={index}>
                <div
                  className={`container ${
                    index % 2 === 0 ? 'pt-lg-5' : 'pb-lg-5'
                  }`}
                >
                  <div
                    className={`row ${
                      index % 2 === 0 ? 'py-5' : 'pt-lg-5 pb-5'
                    }`}
                  >
                    {index % 2 === 0 ? (
                      <>
                        <div className="col-lg-6 pr-lg-5">
                          <MainContent content={item.content} />
                        </div>
                        <div className="col-lg-6 d-flex flex-wrap align-items-center">
                          <UPSColumnRight className="position-relative w-100 d-flex align-items-center justify-content-start justify-content-lg-center px-lg-5 py-3 px-3 py-lg-0">
                            <StyledUl className="py-4 py-lg-5">
                              {item.usps.map((usp, i) => (
                                <li
                                  className="d-flex align-items-center"
                                  key={i}
                                >
                                  <img
                                    src={checkBlue}
                                    width="30"
                                    className="mr-4"
                                    alt="item"
                                  />
                                  <span>{usp.item}</span>
                                </li>
                              ))}
                            </StyledUl>
                          </UPSColumnRight>
                        </div>
                      </>
                    ) : (
                      <>
                        <ColumnContent className="col-lg-6 d-flex flex-wrap align-items-center justify-content-end mb-4 mb-lg-0">
                          {item.videoUrl ? (
                            <UPSColumnLeft className="position-relative w-100 d-flex align-items-center justify-content-start justify-content-lg-center px-lg-0 py-3 px-3 py-lg-0">
                              <Video controls>
                                <source src={item.videoUrl} />
                              </Video>
                            </UPSColumnLeft>
                          ) : (
                            <UPSColumnLeft className="position-relative w-100 d-flex align-items-center justify-content-start justify-content-lg-center px-lg-5 py-3 px-3 py-lg-0">
                              <StyledUl className="py-4 py-lg-5">
                                {item.usps.map((usp, ind) => (
                                  <li
                                    className="d-flex align-items-center"
                                    key={ind}
                                  >
                                    <img
                                      src={checkBlue}
                                      width="30"
                                      className="mr-4"
                                      alt="item"
                                    />
                                    <span>{usp.item}</span>
                                  </li>
                                ))}
                              </StyledUl>
                            </UPSColumnLeft>
                          )}
                        </ColumnContent>
                        <div className="col-lg-6 pl-lg-5">
                          <MainContent content={item.content} />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </section>
            </>
          ))}

          <AboutBG>
            <Plaatjie image={page.paginaVerkoopplannen.aboutUs.background} />
          </AboutBG>

          <AboutUs className="position-relative mb-5 pb-lg-5">
            <div className="container">
              <div className="row">
                <Box className="col-12 mx-auto py-5">
                  <div className="col-lg-8 d-flex flex-wrap align-items-center justify-content-center mx-auto text-center">
                    <MainContent
                      content={page.paginaVerkoopplannen.aboutUs.content}
                    />
                    <StyledButton
                      to={page.paginaVerkoopplannen.aboutUs.button.url}
                    >
                      {page.paginaVerkoopplannen.aboutUs.button.title}
                    </StyledButton>
                  </div>
                </Box>
              </div>
            </div>
          </AboutUs>

          <section>
            <ReviewTitle
              className="pl-4 pl-lg-5"
              content={page.paginaVerkoopplannen.review.title}
            />
            <div className="container">
              <div className="row pb-5">
                <div className="col-lg-8 px-0">
                  <ReviewBox className="d-flex flex-wrap align-items-center">
                    <div className="col-lg-9 px-lg-0">
                      <MainContent
                        content={page.paginaVerkoopplannen.review.review}
                      />
                    </div>
                    <div className="col-lg-3">
                      <ReviewImage
                        image={page.paginaVerkoopplannen.review.image}
                        className="position-absolute"
                      />
                    </div>
                  </ReviewBox>
                </div>
              </div>
            </div>
          </section>

          <Appointment id="gesprek" className="mt-3 mt-lg-5">
            <div className="container">
              <div className="row py-5">
                <div className="col-12 text-center">
                  <MainContent
                    content={page.paginaVerkoopplannen.form.content}
                    className="mb-5"
                  />
                </div>
                <div className="col-lg-8 mx-lg-auto px-4 px-lg-0">
                  <GravityForm id={7} greenButton smallButton />
                </div>
              </div>
            </div>
          </Appointment>
        </Dienst>
      </>
    {/* )} */}
  </Layout>
)

export const pageQuery = graphql`
  query ($databaseId: Int!) {
    page: wpPage(databaseId: { eq: $databaseId }) {
      title
      databaseId
      paginaVerkoopplannen {
        bannerNew {
          background {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, width: 2000)
              }
            }
          }
          description
          button {
            title
            url
          }
          imagePerson {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, width: 212)
              }
            }
          }
        }
        service {
          content
          button {
            title
            url
          }
          usps {
            item
          }
          videoUrl
        }
        aboutUs {
          content
          button {
            title
            url
          }
          background {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, width: 2000)
              }
            }
          }
        }
        review {
          title
          review
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, width: 296)
              }
            }
          }
        }
        form {
          content
          gravityFormId
        }
      }
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublisher
        opengraphPublishedTime
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          sourceUrl
        }
        twitterImage {
          sourceUrl
        }
      }
    }
  }
`

export default HuisVerkopenTemplate
